<div class="dealer" *ngIf="dataBeSeller">
    <div class="dealer__highlight">
        <div class="dealer__highlight--content-text container">
            <h3 class="dealer__highlight--title">{{ dataBeSeller.text_block_title}}</h3>
            <p class="dealer__highlight--text">
                {{ dataBeSeller.text_block_description}}
            </p>
        </div>
        <app-image class="desktop" *ngIf="dataBeSeller.text_block_image" [path]="dataBeSeller.text_block_image.url" [title]="dataBeSeller.text_block_image.alt" type="banner"></app-image>
        <app-image class="mobile" *ngIf="dataBeSeller.text_block_image_mobile" [path]="dataBeSeller.text_block_image_mobile.url" [title]="dataBeSeller.text_block_image_mobile.alt" type="banner"></app-image>
    </div>
    <div class="dealer__numbers dealer__numbers--desktop">
        <div class="container">
            <div class="dealer__numbers--content-text">
                <h3 class="dealer__numbers--title">{{ dataBeSeller.your_business_title}}</h3>
                <p class="dealer__numbers--text">{{ dataBeSeller.your_business_subtitle}}</p>
                <span class="dealer__numbers--sub-text">{{ dataBeSeller.your_business_subtext}}</span>
            </div>
            <div class="dealer__numbers--content-insets">
                <app-image path="./assets/images/revendedor/mapa.png"></app-image>
                <div class="dealer__numbers--content-numbers">
                    <div class="block-number">
                        <span>{{ dataBeSeller.your_business_counter_first_text_first}}</span>
                        <div class="block">
                            {{ numberYears }}
                        </div>
                        <span>{{ dataBeSeller.your_business_counter_first_text_second}}</span>
                    </div>
                    <div class="block-number">
                        <span>{{ dataBeSeller.your_business_counter_second_text_first}}</span>
                        <div class="block">
                            {{ numberDealers }}
                        </div>
                        <span>{{ dataBeSeller.your_business_counter_second_text_second}}</span>
                    </div>
                </div>
                <div class="dealer__numbers--content-insets--content-text" [innerHTML]="dataBeSeller.your_business_text">
                </div>
            </div>
        </div>
    </div>
    <div class="dealer__numbers dealer__numbers--mobile">
        <div class="container">
            <div class="dealer__numbers--content-text">
                <h3 class="dealer__numbers--title">{{ dataBeSeller.your_business_title}}</h3>
                <p class="dealer__numbers--text">{{ dataBeSeller.your_business_subtitle}}</p>
                <span class="dealer__numbers--sub-text">{{ dataBeSeller.your_business_subtext}}</span>
            </div>
            <div class="dealer__numbers--content-insets">
                <div>
                    <app-image path="./assets/images/revendedor/mapa.png"></app-image>
                    <div class="dealer__numbers--content-insets--content-text" [innerHTML]="dataBeSeller.your_business_text">
                    </div>
                </div>
                <div class="dealer__numbers--content-numbers--mobile">
                    <div class="block-number">
                        <span>{{ dataBeSeller.your_business_counter_first_text_first}}</span>
                        <div class="block-grey">
                            {{ numberYears }}
                        </div>
                        <div class="block-black">
                            <span>{{ dataBeSeller.your_business_counter_first_text_second}}</span>
                        </div>
                    </div>
                    <div class="block-number">
                        <span>{{ dataBeSeller.your_business_counter_second_text_first}}</span>
                        <div class="block-grey">
                            {{ numberDealers }}
                        </div>
                        <div class="block-black">
                            <span>{{ dataBeSeller.your_business_counter_second_text_second}}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="dealer__advantage">
        <div class="container">
            <h3 class="dealer__advantage--title">{{dataBeSeller.authorized_resaller_title}}</h3>
            <div class="dealer__advantage--wrapper-content">
                <div class="items" *ngFor="let item of dataBeSeller.block_3_icons_list">
                    <app-image *ngIf="item.icon" [path]="item.icon.url"></app-image>
                    <span>{{item.text}}</span>
                </div>
            </div>
            <h4 class="dealer__advantage--sub-title">{{dataBeSeller.authorized_resaller_title}}</h4>
            <p class="dealer__advantage--text">{{dataBeSeller.authorized_resaller_text_second}}</p>
        </div>
        <div class="dealer__advantage--wrapper-cards">
            <app-card-dealer [data]="dataCardDelaer"></app-card-dealer>
        </div>
        <div class="dealer__advantage--wrapper-footer container">
            <div class="row" *ngFor="let item of dataBeSeller.block_5_icons_list">
                <div class="col-3">
                    <figure *ngIf="item.icon">
                        <img [src]="item.icon.url" alt="">
                    </figure>
                </div>
                <div class="col-9">
                    <p>{{item.text}}</p>
                </div>
            </div>
        </div>
    </div> 
    <div class="dealer__steps">
        <div class="container">
            <h3 class="dealer__steps--title">
                {{dataBeSeller.be_part_title}}
            </h3>
            <div class="dealer__steps--wrapper-steps container">
                <app-card-steps *ngFor="let item of dataBeSeller.block_6_icons_list; index as i" [data]="item" [index]="i"></app-card-steps>
            </div>
            <span class="dealer__steps--alert">{{dataBeSeller.be_part_text}}</span>
        </div>
    </div> 
    <div class="dealer__requirements" *ngFor="let item of dataBeSeller.block_7_icons_list; index as i">
        <div class="container">
            <div class="requirements">
                <figure>
                    <img [src]="item.icon.url" alt="">
                </figure>
                <div class="content-text">
                    <p [ngClass]="{'desktop': i === 2}" class="dealer__requirements--text">{{item.text}}</p>
                    <p *ngIf="i === 2 && lang === 'pt'" class="dealer__requirements--text mobile">Equipe mínima de <br>1 administrativo, <br>1 vendedor e <br>1 técnico instalador</p>
                    <p *ngIf="i === 2 && lang === 'es'" class="dealer__requirements--text mobile">Equipo mínimo de <br>1 administrador, <br>1 comercial y <br>1 técnico instalador</p>
                    <div class="dealer__requirements--sub-text" [innerHTML]="item.subtext"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="dealer__form">
        <h3 class="dealer__form--title container">
            {{dataBeSeller.form_title}}
        </h3>
        <app-form-revendedor [lang]="lang"></app-form-revendedor>
    </div>
</div>

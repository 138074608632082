import { NgModule } from '@angular/core'
import { BrowserModule, Meta } from '@angular/platform-browser'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { StoreModule } from '@ngrx/store'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { NgxMaskModule } from 'ngx-mask'
import { CommonModule, JsonPipe } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { GoogleMapsModule } from '@angular/google-maps'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { stateReducers } from './state/reducers'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { InputComponent } from './components/form/input/input.component'
import { SelectComponent } from './components/form/select/select.component'
import { CheckboxComponent } from './components/form/checkbox/checkbox.component'
import { RadiosComponent } from './components/form/radios/radios.component'
import { TableListComponent } from './components/table-list/table-list.component'
import { ImageComponent } from './components/image/image.component'
import { TabComponent } from './components/tabs/tab/tab.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { AccordionComponent } from './components/accordion/accordion.component'
import { ModalDefaultComponent } from './components/modals/modal-default/modal-default.component'
import { ModalForgotPassword } from './components/modals/modal-forgot-password/modal-forgot-password'
import { SetValueDirective } from './directives/set-value.directive'
import { SetFormControlErrorDirective } from './directives/set-form-control-error.directive'
import { AuthInterceptorService } from './services/auth/auth-interceptor.service'
import { LoadingGlobalComponent } from './components/loading/loading-global/loading-global.component'
import { LoadingLocalComponent } from './components/loading/loading-local/loading-local.component'
import { LoadingProgressComponent } from './components/loading/loading-progress/loading-progress.component'
import { VersionComponent } from './components/version/version.component'
import { FaqComponent } from './pages/faq/faq.component'
import { CheckboxCustomComponent } from './components/form/checkbox-custom/checkbox-custom.component'
import { ModalDefaultInfoComponent } from './components/modals/modal-default-info/modal-default-info.component'
import { ModalActiveSectionComponent } from './components/modals/modal-active-section/modal-active-section.component'
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component'
import { HomeComponent } from './pages/home/home.component'
import { HeaderComponent } from './components/main/header/header.component'
import { FooterComponent } from './components/main/footer/footer.component'
import { BannerComponent } from './components/banner/banner.component'
import { MybuttonComponent } from './components/mybutton/mybutton.component'
import { CardComponent } from './components/card/card.component'
import { CardPurifiersComponent } from './components/card-purifiers/card-purifiers.component'
import { HighlightProductComponent } from './components/highlight-product/highlight-product.component'
import { CarouselComponent } from './components/carousel/carousel.component'
import { ProductBannerComponent } from './components/product-banner/product-banner.component'
import { ResellerComponent } from './components/reseller/reseller.component'
import { KnowMoreComponent } from './components/know-more/know-more.component'
import { WhiteCardComponent } from './components/white-card/white-card.component'
import { PostComponent } from './components/post/post.component'
import { MissionComponent } from './pages/mission/mission.component'
import { MissionVisionValuesComponent } from './components/mission-vision-values/mission-vision-values.component'
import { DifferentialComponent } from './pages/differential/differential.component'
import { BannerInternalComponent } from './components/banner-internal/banner-internal.component'
import { PostDifferentialComponent } from './components/post-differential/post-differential.component'
import { CarouselDifferentialComponent } from './components/carousel-differential/carousel-differential.component'
import { PurifiersBannerComponent } from './components/purifiers-banner/purifiers-banner.component'
import { CompareButtonComponent } from './components/compare-button/compare-button.component'
import { CertificationsComponent } from './pages/certifications/certifications.component'
import { CertificationsIceMachineComponent } from './pages/certifications-ice-machine/certifications-ice-machine.component'
import { TrustBannerComponent } from './components/trust-banner/trust-banner.component'
import { CarouselImagesComponent } from './components/carousel-images/carousel-images.component'
import { TextComponent } from './components/text/text.component'
import { EconomyTableComponent } from './pages/economy-table/economy-table.component'
import { EconomyCalculatorComponent } from './components/economy-calculator/economy-calculator.component'
import { DownloadsComponent } from './pages/downloads/downloads.component'
import { DownloadsIceMachineComponent } from './pages/downloads-ice-machine/downloads-ice-machine.component'
import { CardDownloadComponent } from './components/card-download/card-download.component'
import { ListOfProductsComponent } from './components/list-of-products/list-of-products.component'
import { CompareComponent } from './components/compare/compare.component'
import { CompareTableComponent } from './components/compare-table/compare-table.component'
import { HistoryComponent } from './pages/history/history.component'
import { WhereToFindComponent } from './pages/where-to-find/where-to-find.component'
import { WhereToFindComponentOld } from './pages/where-to-find-old/where-to-find-old.component'
import { TimelineComponent } from './components/timeline/timeline.component'
import { MapComponent } from './components/map/map.component'
import { ContactComponent } from './pages/contact/contact.component'
import { TextAreaComponent } from './components/form/text-area/text-area.component'
import { CertificationListComponent } from './components/certification-list/certification-list.component'
import { RevendedorComponent } from './pages/revendedor/revendedor.component'
import { FormRevendedorComponent } from './components/form-revendedor/form-revendedor.component'
import { FiltersComponent } from './components/filters/filters.component'
import { ProcureAquiComponent } from './components/procure-aqui/procure-aqui.component'
import { ElementoFiltranteComponent } from './pages/elemento-filtrante/elemento-filtrante.component'
import { FiltranteBannerComponent } from './components/filtrante-banner/filtrante-banner.component'
import { GotaFiltranteComponent } from './components/gota-filtrante/gota-filtrante.component'
import { BlueCardComponent } from './components/blue-card/blue-card.component'
import { AgmCoreModule } from '@agm/core'
import { InternaProdutoComponent } from './pages/interna-produto/interna-produto.component'
import { DetailsProductComponent } from './components/details-product/details-product.component'
import { SustentabilidadeComponent } from './pages/sustentabilidade/sustentabilidade.component'
import { SeloABRAFIPAComponent } from './pages/selo-abrafipa/selo-abrafipa.component'
import { ExportationComponent } from './pages/exportation/exportation.component'
import { WhatsappComponent } from './components/whatsapp/whatsapp.component'
import { RevendaComponent } from './pages/revenda/revenda.component'
import { CarouselResellerComponent } from './components/carousel-reseller/carousel-reseller.component'
import { CompareModalComponent } from './components/modals/compare-modal/compare-modal.component'
import { LettersOnlyDirective } from './directives/letters-only.directive'
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { ParallaxDirective } from './directives/parallax.directive'
import { ProductsComponent } from './pages/products/products.component';
import { PressRoomComponent } from './pages/press-room/press-room.component';
import { IceMachineComponent } from './pages/ice-machine/ice-machine.component';
import { IceMachineBannerComponent } from './components/ice-machine-banner/ice-machine-banner.component';
import { IceMachineBenefitsComponent } from './components/ice-machine-benefits/ice-machine-benefits.component';
import { IceFormatComponent } from './components/ice-format/ice-format.component';
import { IceImagesComponent } from './components/ice-images/ice-images.component';
import { InternHighlightProductComponent } from './components/intern-highlight-product/intern-highlight-product.component';
import { IceTabsComponent } from './components/ice-tabs/ice-tabs.component';
import { IceFormComponent } from './components/modals/ice-form/ice-form.component';
import { NewsComponent } from './components/news/news.component';
import { CarouselIceProductsComponent } from './components/carousel-ice-products/carousel-ice-products.component';
import { CarouselDirective } from './directives/carousel.directive'
import {CardHighlightComponent} from "./components/card-highlight/card-highlight.component";
import {BannerBlueComponent} from "./components/banner-blue/banner-blue.component";
import {BannerCtaComponent} from "./components/banner-cta/banner-cta.component";
import {CardProductsComponent} from "./components/card-products/card-products.component";
import {GalleryComponent} from "./components/gallery/gallery.component";
import {IceMachineDetailsComponent} from "./components/modals/ice-machine-details/ice-machine-details.component";
import {IceMachineDetails2Component} from "./components/modals/ice-machine-details-2/ice-machine-details-2.component";
import {PurifiersDetailsComponent} from "./components/modals/purifiers-details/purifiers-details.component";
import { CardDealerComponent } from './components/card-dealer/card-dealer.component';
import { CardStepsComponent } from './components/card-steps/card-steps.component';


@NgModule({
    declarations: [
        GalleryComponent,
        NewsComponent,
        PressRoomComponent,
        AppComponent,
        NotFoundComponent,
        InputComponent,
        RevendaComponent,
        SelectComponent,
        CheckboxComponent,
        RadiosComponent,
        TableListComponent,
        ImageComponent,
        TabComponent,
        TabsComponent,
        AccordionComponent,
        SetValueDirective,
        SetFormControlErrorDirective,
        ModalDefaultComponent,
        ModalForgotPassword,
        LoadingGlobalComponent,
        LoadingLocalComponent,
        LoadingProgressComponent,
        ModalDefaultInfoComponent,
        VersionComponent,
        FaqComponent,
        CheckboxCustomComponent,
        ModalActiveSectionComponent,
        ForgotPasswordComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        BannerComponent,
        MybuttonComponent,
        CardComponent,
        HighlightProductComponent,
        CarouselComponent,
        ProductBannerComponent,
        ResellerComponent,
        KnowMoreComponent,
        WhiteCardComponent,
        PostComponent,
        MissionComponent,
        MissionVisionValuesComponent,
        DifferentialComponent,
        BannerInternalComponent,
        PostDifferentialComponent,
        CarouselDifferentialComponent,
        ProductsComponent,
        PurifiersBannerComponent,
        CompareButtonComponent,
        CertificationsComponent,
        TrustBannerComponent,
        CarouselImagesComponent,
        TextComponent,
        EconomyTableComponent,
        EconomyCalculatorComponent,
        DownloadsComponent,
        DownloadsIceMachineComponent,
        CardDownloadComponent,
        ListOfProductsComponent,
        CompareComponent,
        CompareTableComponent,
        HistoryComponent,
        WhereToFindComponent,
        WhereToFindComponentOld,
        TimelineComponent,
        MapComponent,
        ContactComponent,
        TextAreaComponent,
        CertificationListComponent,
        RevendedorComponent,
        FormRevendedorComponent,
        FiltersComponent,
        ProcureAquiComponent,
        ElementoFiltranteComponent,
        FiltranteBannerComponent,
        GotaFiltranteComponent,
        BlueCardComponent,
        InternaProdutoComponent,
        DetailsProductComponent,
        SustentabilidadeComponent,
        SeloABRAFIPAComponent,
        ExportationComponent,
        WhatsappComponent,
        CarouselResellerComponent,
        CompareModalComponent,
        LettersOnlyDirective,
        NumbersOnlyDirective,
        ParallaxDirective,
        IceMachineComponent,
        IceMachineBannerComponent,
        IceMachineBenefitsComponent,
        IceFormatComponent,
        IceImagesComponent,
        InternHighlightProductComponent,
        IceTabsComponent,
        IceFormComponent,
        CarouselIceProductsComponent,
        CarouselDirective,
        CardPurifiersComponent,
        CardHighlightComponent,
        BannerBlueComponent,
        BannerCtaComponent,
        CardProductsComponent,
        CertificationsIceMachineComponent,
        IceMachineDetailsComponent,
        IceMachineDetails2Component,
        PurifiersDetailsComponent,
        CardDealerComponent,
        CardStepsComponent,
    ],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		HttpClientModule,
		LazyLoadImageModule,
		StoreModule.forRoot({ ...stateReducers }, {}),
		NgbModule,
		NgbTypeaheadModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMaskModule.forRoot(),
		CommonModule,
		JsonPipe,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			positionClass: 'toast-top-right',
		}),
		SlickCarouselModule,
		GoogleMapsModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyAOQCFyem_Agn0x942U4LIovUO6B0zD_qQ',
		}),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
		Meta,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
